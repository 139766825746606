export const Team = () => {
    return(
        <>
            <div id="team" className="main-team">
                <h2 className="title-team">Our Team</h2>
                <p className="page-team">We are international and welcoming team</p>
                <div className="team">
                    <div className="team-card">
                        <img src="./img/team/Ignas-Brazauskas.png" alt=""  className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Ignas Brazauskas</h3>
                            <p className="job-title">founder &  CEO</p>
                            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.9212 0.574707H0.186157V17.552H22.9212V0.574707Z" fill="#006A44"/>
                                <path d="M22.9212 11.9675H0.186157V17.6258H22.9212V11.9675Z" fill="#C1272D"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.186157 0.574707H22.9216V6.23306H0.186157V0.574707Z" fill="#FDB913"/>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Liudmila-Mikhailovskaya.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Liudmila Mikhailovskaya</h3>
                            <p className="job-title">СOO</p>
                            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.9212 0.574707H0.186157V17.552H22.9212V0.574707Z" fill="#006A44"/>
                                <path d="M22.9212 11.9675H0.186157V17.6258H22.9212V11.9675Z" fill="#C1272D"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.186157 0.574707H22.9216V6.23306H0.186157V0.574707Z" fill="#FDB913"/>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Valeriia-Shybika.png" alt="" className="imteam" />
                        <div className="info-card">
                            <h3 className="name-person">Valeriia Shybika</h3>
                            <p className="job-title">project coordinator</p>
                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.287476 0.074707H23.0229V17.1263H0.287476V0.074707Z" fill="#FFD700"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.287476 0.074707H23.0229V8.60048H0.287476V0.074707Z" fill="#0057B8"/>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Nazar-Halych.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Nazar Halych</h3>
                            <p className="job-title">project coordinator</p>
                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.287476 0.074707H23.0229V17.1263H0.287476V0.074707Z" fill="#FFD700"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.287476 0.074707H23.0229V8.60048H0.287476V0.074707Z" fill="#0057B8"/>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Karthikeyan-Kannan.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Karthikeyan Kannan</h3>
                            <p className="job-title">CBO</p>
                            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_745_146)">
                                <path d="M0.027832 0.948242H22.7632V6.63209H0.027832V0.948242Z" fill="#FF9933"/>
                                <path d="M0.027832 6.63232H22.7632V12.3162H0.027832V6.63232Z" fill="white"/>
                                <path d="M0.027832 12.3159H22.7632V17.9998H0.027832V12.3159Z" fill="#128807"/>
                                <path d="M11.3956 11.7478C12.6513 11.7478 13.6692 10.7299 13.6692 9.47422C13.6692 8.21858 12.6513 7.20068 11.3956 7.20068C10.14 7.20068 9.12207 8.21858 9.12207 9.47422C9.12207 10.7299 10.14 11.7478 11.3956 11.7478Z" fill="#000088"/>
                                <path d="M11.3958 11.4636C12.4945 11.4636 13.3852 10.5729 13.3852 9.47421C13.3852 8.37552 12.4945 7.48486 11.3958 7.48486C10.2972 7.48486 9.40649 8.37552 9.40649 9.47421C9.40649 10.5729 10.2972 11.4636 11.3958 11.4636Z" fill="white"/>
                                <path d="M11.3957 9.87191C11.6154 9.87191 11.7935 9.69378 11.7935 9.47404C11.7935 9.2543 11.6154 9.07617 11.3957 9.07617C11.1759 9.07617 10.9978 9.2543 10.9978 9.47404C10.9978 9.69378 11.1759 9.87191 11.3957 9.87191Z" fill="#000088"/>
                                <path d="M13.3545 9.83585C13.4105 9.84323 13.4619 9.80379 13.4693 9.74777C13.4767 9.69175 13.4372 9.64036 13.3812 9.63298C13.3252 9.62561 13.2738 9.66504 13.2664 9.72106C13.2591 9.77708 13.2985 9.82848 13.3545 9.83585Z" fill="#000088"/>
                                <path d="M11.3956 11.4632L11.4638 10.2696L11.3956 9.70117L11.3274 10.2696L11.3956 11.4632Z" fill="#000088"/>
                                <path d="M13.1731 10.3083C13.2136 10.3423 13.2735 10.3376 13.3067 10.2978C13.34 10.258 13.3342 10.1982 13.2936 10.1642C13.2531 10.1301 13.1932 10.1348 13.1599 10.1746C13.1267 10.2144 13.1325 10.2742 13.1731 10.3083Z" fill="#000088"/>
                                <path d="M10.8627 11.3809L11.244 10.2808L11.3549 9.70886L11.1484 10.2157L10.8627 11.3809Z" fill="#000088"/>
                                <path d="M12.907 10.7623C12.9494 10.7991 13.0137 10.7945 13.0504 10.752C13.0872 10.7096 13.0827 10.6454 13.0402 10.6086C12.9978 10.5718 12.9336 10.5764 12.8968 10.6188C12.86 10.6613 12.8646 10.7255 12.907 10.7623Z" fill="#000088"/>
                                <path d="M10.3996 11.1959L11.056 10.1984L11.2835 9.67172L10.9407 10.1284L10.3996 11.1959Z" fill="#000088"/>
                                <path d="M12.519 11.1098C12.55 11.1577 12.6142 11.1712 12.6624 11.1399C12.7107 11.1086 12.7246 11.0444 12.6936 10.9965C12.6626 10.9485 12.5984 10.935 12.5501 10.9663C12.5019 10.9976 12.488 11.0618 12.519 11.1098Z" fill="#000088"/>
                                <path d="M9.98204 10.8736L10.8768 10.0954L11.2423 9.64264L10.7947 9.98399L9.98204 10.8736Z" fill="#000088"/>
                                <path d="M12.0628 11.3529C12.0851 11.4041 12.1449 11.4272 12.1965 11.4046C12.2481 11.3819 12.2719 11.322 12.2497 11.2709C12.2275 11.2197 12.1676 11.1966 12.116 11.2192C12.0644 11.2419 12.0406 11.3017 12.0628 11.3529Z" fill="#000088"/>
                                <path d="M9.67284 10.4699L10.7406 9.92971L11.1985 9.58682L10.6721 9.81368L9.67284 10.4699Z" fill="#000088"/>
                                <path d="M11.5524 11.4594C11.5593 11.5156 11.6107 11.5553 11.6672 11.5481C11.7236 11.5409 11.7638 11.4895 11.7569 11.4333C11.75 11.3771 11.6986 11.3374 11.6421 11.3446C11.5856 11.3518 11.5454 11.4032 11.5524 11.4594Z" fill="#000088"/>
                                <path d="M9.4699 9.96411L10.642 9.77901L11.1805 9.55729L10.6154 9.59803L9.4699 9.96411Z" fill="#000088"/>
                                <path d="M11.0257 11.4515C11.0287 11.5118 11.0801 11.5581 11.1405 11.555C11.2009 11.5519 11.2474 11.5005 11.2444 11.4402C11.2414 11.3799 11.19 11.3336 11.1296 11.3367C11.0692 11.3398 11.0227 11.3912 11.0257 11.4515Z" fill="#000088"/>
                                <path d="M9.41121 9.40933L10.5925 9.50048L11.1627 9.53868L10.603 9.49343L9.41121 9.40933Z" fill="#000088"/>
                                <path d="M10.5064 11.2958C10.5027 11.3612 10.5566 11.421 10.6268 11.4295C10.6969 11.4379 10.7568 11.3918 10.7604 11.3264C10.7641 11.261 10.7102 11.2012 10.6401 11.1927C10.5699 11.1843 10.5101 11.2304 10.5064 11.2958Z" fill="#000088"/>
                                <path d="M9.46694 9.26313L10.64 9.37767L11.1828 9.1108L10.6186 8.94221L9.46694 9.26313Z" fill="#000088"/>
                                <path d="M10.1068 10.9847C10.0702 11.0268 10.075 11.0911 10.1177 11.1281C10.1603 11.1652 10.2245 11.161 10.2611 11.1188C10.2977 11.0767 10.2928 11.0124 10.2502 10.9754C10.2076 10.9383 10.1434 10.9425 10.1068 10.9847Z" fill="#000088"/>
                                <path d="M9.67293 8.47821L10.6724 9.13451L11.1988 9.36159L10.7407 9.01875L9.67293 8.47821Z" fill="#000088"/>
                                <path d="M9.76213 10.5962C9.71321 10.6265 9.69812 10.6907 9.72842 10.7396C9.75872 10.7885 9.82295 10.8035 9.87187 10.7732C9.92079 10.7429 9.93588 10.6786 9.90558 10.6297C9.87528 10.5809 9.81105 10.5658 9.76213 10.5962Z" fill="#000088"/>
                                <path d="M9.99469 8.06154L10.7772 8.95592L11.2293 9.31953L10.8849 8.87154L9.99469 8.06154Z" fill="#000088"/>
                                <path d="M9.53198 10.1288C9.47224 10.1442 9.43523 10.2041 9.44932 10.2625C9.46341 10.3209 9.52326 10.3558 9.583 10.3404C9.64273 10.325 9.67974 10.2651 9.66566 10.2067C9.65157 10.1483 9.59172 10.1134 9.53198 10.1288Z" fill="#000088"/>
                                <path d="M10.4208 7.74249L10.9119 8.81342L11.2622 9.28604L11.0697 8.76282L10.4208 7.74249Z" fill="#000088"/>
                                <path d="M9.42867 9.61916C9.36833 9.61856 9.31694 9.66899 9.31388 9.73179C9.31082 9.79459 9.35726 9.84598 9.4176 9.84658C9.47794 9.84717 9.52933 9.79675 9.53239 9.73395C9.53544 9.67115 9.48901 9.61975 9.42867 9.61916Z" fill="#000088"/>
                                <path d="M10.9736 7.54284L10.9979 8.71694L11.2438 9.26459L11.3154 8.70143L10.9736 7.54284Z" fill="#000088"/>
                                <path d="M9.44435 9.11755C9.39242 9.10733 9.34103 9.14214 9.32956 9.19531C9.31809 9.24848 9.35088 9.29987 9.40281 9.3101C9.45473 9.32032 9.50613 9.28551 9.5176 9.23234C9.52907 9.17917 9.49627 9.12778 9.44435 9.11755Z" fill="#000088"/>
                                <path d="M11.4594 7.50513L11.3686 8.65037L11.3323 9.22604L11.3779 8.69145L11.4594 7.50513Z" fill="#000088"/>
                                <path d="M9.60441 8.62471C9.55631 8.59911 9.49646 8.61745 9.47074 8.66567C9.44502 8.71389 9.46317 8.77374 9.51128 8.79934C9.55939 8.82495 9.61924 8.80661 9.64496 8.75839C9.67067 8.71017 9.65252 8.65032 9.60441 8.62471Z" fill="#000088"/>
                                <path d="M11.9171 7.55275L11.54 8.68712L11.4481 9.25393L11.6588 8.7232L11.9171 7.55275Z" fill="#000088"/>
                                <path d="M9.88801 8.18926C9.84748 8.15065 9.78326 8.15228 9.74457 8.19289C9.70587 8.23351 9.70737 8.29773 9.7479 8.33634C9.78843 8.37495 9.85266 8.37332 9.89135 8.33271C9.93004 8.29209 9.92855 8.22787 9.88801 8.18926Z" fill="#000088"/>
                                <path d="M12.3997 7.75844L11.7405 8.74167L11.5 9.27047L11.84 8.82377L12.3997 7.75844Z" fill="#000088"/>
                                <path d="M10.2778 7.84383C10.2497 7.7932 10.1855 7.77535 10.1344 7.80395C10.0833 7.83255 10.0647 7.89678 10.0928 7.9474C10.121 7.99802 10.1852 8.01588 10.2363 7.98728C10.2874 7.95867 10.306 7.89445 10.2778 7.84383Z" fill="#000088"/>
                                <path d="M12.8175 8.08462L11.92 8.84048L11.5415 9.29663L11.9863 8.97085L12.8175 8.08462Z" fill="#000088"/>
                                <path d="M10.7285 7.59482C10.7062 7.54366 10.6464 7.52056 10.5948 7.54322C10.5432 7.56588 10.5193 7.62573 10.5416 7.67689C10.5638 7.72806 10.6236 7.75116 10.6752 7.7285C10.7268 7.70583 10.7507 7.64599 10.7285 7.59482Z" fill="#000088"/>
                                <path d="M13.1185 8.47878L12.0507 9.01905L11.5927 9.36193L12.1191 9.13504L13.1185 8.47878Z" fill="#000088"/>
                                <path d="M11.2392 7.48907C11.2324 7.43277 11.181 7.39287 11.1244 7.39996C11.0678 7.40705 11.0275 7.45844 11.0343 7.51475C11.0411 7.57105 11.0925 7.61095 11.1491 7.60386C11.2057 7.59677 11.246 7.54538 11.2392 7.48907Z" fill="#000088"/>
                                <path d="M13.3217 8.9846L12.1495 9.16859L11.6108 9.39048L12.1759 9.35052L13.3217 8.9846Z" fill="#000088"/>
                                <path d="M11.7674 7.49968C11.7654 7.43752 11.714 7.38812 11.6526 7.38936C11.5911 7.39059 11.5429 7.44198 11.5448 7.50414C11.5468 7.56631 11.5982 7.6157 11.6596 7.61447C11.7211 7.61323 11.7693 7.56184 11.7674 7.49968Z" fill="#000088"/>
                                <path d="M13.3771 9.53908L12.2022 9.44784L11.631 9.40929L12.1863 9.45447L13.3771 9.53908Z" fill="#000088"/>
                                <path d="M12.2841 7.65511C12.2881 7.5913 12.2346 7.53145 12.1647 7.52144C12.0949 7.51142 12.035 7.55503 12.0311 7.61884C12.0271 7.68265 12.0806 7.74249 12.1504 7.75251C12.2203 7.76253 12.2802 7.71892 12.2841 7.65511Z" fill="#000088"/>
                                <path d="M13.3221 9.73467L12.1498 9.50343L11.6105 9.78788L12.1754 10.038L13.3221 9.73467Z" fill="#000088"/>
                                <path d="M12.696 7.95037C12.7261 7.90091 12.7107 7.83668 12.6616 7.80692C12.6125 7.77715 12.5483 7.79312 12.5182 7.84258C12.488 7.89203 12.5034 7.95626 12.5525 7.98602C12.6016 8.01579 12.6659 7.99983 12.696 7.95037Z" fill="#000088"/>
                                <path d="M13.1286 10.4506L12.1049 9.80119L11.5822 9.60621L12.0572 9.95597L13.1286 10.4506Z" fill="#000088"/>
                                <path d="M13.0435 8.33735C13.0843 8.29881 13.0864 8.23459 13.048 8.1939C13.0097 8.15321 12.9455 8.15146 12.9046 8.18999C12.8637 8.22853 12.8617 8.29275 12.9 8.33344C12.9383 8.37413 13.0026 8.37588 13.0435 8.33735Z" fill="#000088"/>
                                <path d="M12.812 10.8695L11.9926 9.98124L11.546 9.64608L11.9162 10.1002L12.812 10.8695Z" fill="#000088"/>
                                <path d="M13.2795 8.80142C13.3279 8.77641 13.3466 8.71657 13.3212 8.66775C13.2959 8.61893 13.236 8.59963 13.1876 8.62464C13.1391 8.64965 13.1204 8.7095 13.1458 8.75831C13.1712 8.80713 13.231 8.82643 13.2795 8.80142Z" fill="#000088"/>
                                <path d="M12.4009 11.1896L11.8375 10.1243L11.4981 9.67766L11.7412 10.2065L12.4009 11.1896Z" fill="#000088"/>
                                <path d="M13.3876 9.31038C13.44 9.29963 13.4736 9.24824 13.4626 9.19559C13.4516 9.14294 13.4003 9.10896 13.3478 9.11971C13.2954 9.13045 13.2618 9.18184 13.2728 9.2345C13.2838 9.28715 13.3352 9.32112 13.3876 9.31038Z" fill="#000088"/>
                                <path d="M11.9232 11.3903L11.6493 10.2215L11.441 9.69848L11.5437 10.2669L11.9232 11.3903Z" fill="#000088"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_745_146">
                                <rect width="22.7354" height="17.0516" fill="white" transform="translate(0.027832 0.948242)"/>
                                </clipPath>
                                </defs>
                            </svg>

                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Zarzis-Al-Mahmud.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Zarzis Al Mahmud</h3>
                            <p className="job-title">facilitator South Asia</p>
                            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_745_372)">
                                <path d="M0.129028 0.948242H22.8644V17.9998H0.129028V0.948242Z" fill="#006A4E"/>
                                <path d="M10.0758 15.1577C13.2149 15.1577 15.7597 12.613 15.7597 9.47389C15.7597 6.33479 13.2149 3.79004 10.0758 3.79004C6.93672 3.79004 4.39197 6.33479 4.39197 9.47389C4.39197 12.613 6.93672 15.1577 10.0758 15.1577Z" fill="#F42A41"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_745_372">
                                <rect width="22.7354" height="17.0516" fill="white" transform="translate(0.129028 0.948242)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Ismot-Jubair-Jami.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Ismot Jubair Jami</h3>
                            <p className="job-title">project development</p>
                            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_745_372)">
                                <path d="M0.129028 0.948242H22.8644V17.9998H0.129028V0.948242Z" fill="#006A4E"/>
                                <path d="M10.0758 15.1577C13.2149 15.1577 15.7597 12.613 15.7597 9.47389C15.7597 6.33479 13.2149 3.79004 10.0758 3.79004C6.93672 3.79004 4.39197 6.33479 4.39197 9.47389C4.39197 12.613 6.93672 15.1577 10.0758 15.1577Z" fill="#F42A41"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_745_372">
                                <rect width="22.7354" height="17.0516" fill="white" transform="translate(0.129028 0.948242)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/David-Arturo-Cortes-Lopez.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">David Arturo Cortes Lopez</h3>
                            <p className="job-title">facilitator South America</p>
                            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.331665 0.448242H23.0671V17.4998H0.331665V0.448242Z" fill="#FFE800"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.331665 8.97412H23.0671V17.4999H0.331665V8.97412Z" fill="#00148E"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.331665 13.2368H23.0671V17.4997H0.331665V13.2368Z" fill="#DA0010"/>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Majdi-Chebil.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Majdi Chebil</h3>
                            <p className="job-title">facilitator Middle East and Africa</p>
                            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_745_269)">
                                <path d="M0.129028 0.257812H22.8644V17.3094H0.129028V0.257812Z" fill="#E70013"/>
                                <path d="M11.4967 4.49222C10.3647 4.48751 9.2772 4.93268 8.47342 5.7298C7.66964 6.52691 7.21544 7.61068 7.21073 8.74268C7.20602 9.87468 7.65119 10.9622 8.4483 11.766C9.24542 12.5697 10.3292 13.0239 11.4612 13.0287C12.5932 13.0334 13.6807 12.5882 14.4845 11.7911C15.2883 10.994 15.7425 9.91021 15.7472 8.7782C15.7519 7.6462 15.3067 6.55869 14.5096 5.75492C13.7125 4.95114 12.6287 4.49694 11.4967 4.49222ZM14.0544 10.6663C13.6544 11.2074 13.0939 11.6085 12.4526 11.8125C11.8114 12.0165 11.1221 12.0129 10.483 11.8024C9.84384 11.5918 9.28746 11.1849 8.89305 10.6397C8.49864 10.0945 8.28632 9.43869 8.28632 8.76577C8.28632 8.09285 8.49864 7.43709 8.89305 6.89187C9.28746 6.34665 9.84384 5.93977 10.483 5.72919C11.1221 5.5186 11.8114 5.51505 12.4526 5.71903C13.0939 5.923 13.6544 6.32411 14.0544 6.86523C13.687 6.5343 13.2315 6.31697 12.7431 6.23957C12.2547 6.16216 11.7543 6.22799 11.3026 6.4291C10.8508 6.6302 10.467 6.95794 10.1977 7.37266C9.92834 7.78737 9.785 8.27127 9.785 8.76577C9.785 9.26027 9.92834 9.74417 10.1977 10.1589C10.467 10.5736 10.8508 10.9013 11.3026 11.1024C11.7543 11.3035 12.2547 11.3694 12.7431 11.292C13.2315 11.2146 13.687 10.9972 14.0544 10.6663ZM13.8875 9.89544L12.5589 9.4656L11.7383 10.5953V9.19916L10.4097 8.76577L11.7383 8.33238V6.93628L12.5589 8.06595L13.8875 7.63611L13.0669 8.76577L13.8875 9.89544Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_745_269">
                                <rect width="22.7354" height="17.0516" fill="white" transform="translate(0.129028 0.257812)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div className="team-card">
                        <img src="./img/team/Sushil-Phuyal.png" alt="" className="imteam"/>
                        <div className="info-card">
                            <h3 className="name-person">Sushil Phuyal</h3>
                            <p className="job-title">facilitator Nepal</p>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="20px" height="20px" viewBox="0 0 738.000000 900.000000"
                                preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
                                fill="red" stroke="none">
                                <path d="M0 4505 l0 -4495 3542 0 3543 0 -2185 2185 -2185 2185 2327 2 2326 3
                                -2746 1720 c-1511 946 -3169 1984 -3684 2307 l-938 587 0 -4494z m1739 1694
                                l73 -99 76 100 c41 56 78 97 82 93 4 -4 20 -59 35 -120 15 -62 30 -113 32
                                -113 3 0 50 27 105 60 54 33 102 60 107 60 4 0 0 -55 -10 -122 -10 -67 -17
                                -123 -16 -124 1 -1 56 6 122 16 66 10 122 16 124 14 2 -2 -25 -51 -59 -109
                                -34 -58 -61 -107 -59 -109 2 -2 54 -16 114 -31 61 -16 113 -31 117 -35 5 -3
                                -37 -39 -92 -80 -55 -41 -100 -75 -100 -76 0 -1 22 -19 50 -39 58 -42 54 -43
                                170 43 158 117 309 303 396 485 20 43 39 76 41 73 9 -9 -19 -212 -42 -300 -79
                                -310 -294 -592 -579 -758 -445 -260 -1023 -204 -1418 137 -246 212 -403 525
                                -423 841 l-6 89 30 -63 c69 -145 182 -303 287 -403 64 -60 220 -179 235 -179
                                13 0 98 63 99 72 0 4 -43 40 -95 79 -53 40 -93 75 -89 78 3 3 57 20 119 36
                                l114 30 -64 110 c-35 60 -63 110 -62 111 1 1 57 -6 125 -15 142 -20 130 -34
                                107 121 -9 55 -13 103 -11 106 3 2 51 -23 108 -56 56 -33 105 -59 108 -57 4 2
                                20 56 36 120 15 63 31 115 34 115 3 -1 38 -46 79 -101z m186 -2676 l107 -253
                                218 165 c120 91 220 165 222 165 2 0 -11 -118 -29 -262 -18 -145 -32 -268 -30
                                -274 1 -7 89 0 267 23 146 18 268 32 272 30 4 -1 -68 -100 -159 -220 -91 -120
                                -163 -220 -162 -222 2 -1 112 -49 244 -104 132 -56 244 -106 248 -110 7 -7
                                -83 -47 -451 -203 -28 -11 -41 -22 -36 -30 4 -6 77 -103 162 -215 85 -113 151
                                -207 148 -210 -3 -4 -124 8 -268 25 -143 18 -263 31 -265 29 -2 -2 11 -121 29
                                -265 18 -144 30 -264 26 -267 -3 -4 -102 66 -219 155 -117 88 -216 157 -220
                                153 -4 -4 -50 -111 -103 -237 -107 -255 -111 -262 -119 -250 -2 5 -51 117
                                -107 249 -56 132 -103 241 -105 243 -2 2 -102 -71 -222 -162 -120 -91 -219
                                -162 -220 -158 -2 4 12 126 31 272 18 146 32 266 31 268 -1 1 -124 -13 -273
                                -31 -150 -18 -272 -31 -272 -29 0 3 74 103 165 223 l165 218 -42 18 c-24 11
                                -138 59 -255 108 -117 50 -209 93 -205 96 4 3 117 52 252 109 135 56 246 103
                                247 104 1 1 -73 100 -163 221 -91 121 -161 221 -157 222 4 2 127 -12 273 -31
                                146 -18 265 -33 266 -32 1 0 -14 122 -32 271 -18 148 -32 271 -30 273 2 2 102
                                -71 222 -162 120 -91 219 -164 220 -163 1 1 43 101 94 222 51 121 99 235 107
                                254 8 18 16 32 17 30 2 -2 52 -118 111 -256z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}